import {
  BrandLelandApp,
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
  IconGlobe,
} from '@leland-dev/leland-ui-library';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import React from 'react';

import { COACH_MOBILE_APP_APP_STORE_URL } from '../../utils/constants';

interface DownloadMobileAppPromptProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export const DownloadMobileAppPrompt: React.FC<
  DownloadMobileAppPromptProps
> = ({ isOpen, setIsOpen }) => {
  return (
    <div
      className={`fixed left-0 top-0 z-10 w-full transition duration-200 ease-in-out ${
        isOpen ? 'min-h-screen bg-leland-gray-overlay' : ''
      }`}
    >
      <AnimatePresence initial={false}>
        {isOpen ? (
          <motion.div
            className="fixed bottom-0 flex w-screen flex-col items-start rounded-t-3xl bg-white p-6"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{
              ease: 'linear',
              duration: 0.2,
            }}
          >
            <h2 className="text-2xl font-medium text-leland-gray-dark">
              Messaging is better on the app.
            </h2>
            <div className="mt-6 flex w-full items-center justify-between space-x-3">
              <div className="flex items-center space-x-3">
                <BrandLelandApp className="size-10 shrink-0 rounded-lg" />
                <p className="text-lg font-medium text-leland-gray-dark">
                  Open the Leland app
                </p>
              </div>
              <div className="w-24">
                <Link
                  href={COACH_MOBILE_APP_APP_STORE_URL}
                  target="_blank"
                  rel="noopener"
                >
                  <Button
                    label="Open App"
                    buttonColor={ButtonColor.PRIMARY}
                    size={ButtonSize.SMALL}
                    width={ButtonWidth.FULL}
                    rounded
                  />
                </Link>
              </div>
            </div>
            <hr className="mt-6 w-full" />
            <div className="mt-6 flex w-full items-center justify-between space-x-3">
              <div className="flex items-center space-x-3">
                <div className="flex size-10 items-center justify-center rounded-lg bg-leland-gray-hover">
                  <IconGlobe className="size-6 text-leland-gray-light" />
                </div>
                <p className="text-lg font-medium text-leland-gray-dark">
                  Continue in the browser
                </p>
              </div>
              <div className="w-24">
                <Button
                  label="Dismiss"
                  buttonColor={ButtonColor.WHITE}
                  size={ButtonSize.SMALL}
                  width={ButtonWidth.FULL}
                  rounded
                  onClick={() => setIsOpen(false)}
                />
              </div>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};
