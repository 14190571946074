import { addDays, isDateAfter } from '@leland-dev/leland-ui-library';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';

import { DownloadMobileAppPrompt } from '../components/inbox/DownloadMobileAppPrompt';
import AuthedPageBase from '../components/page/AuthedPageBase';
import { useAuth } from '../context/AuthContext';
import { ONBOARDED_COACH_STATUSES } from '../utils/constants';
import { LelandLocalStorage } from '../utils/storage';

const ClientsOverview = dynamic(
  () => import('../components/inbox/ClientsOverview'),
  { ssr: false },
);

const HIDE_MOBILE_APP_PROMPT_KEY = 'hide-mobile-app-prompt';

const Inbox: React.FC = () => {
  const { currentUser } = useAuth();
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  const setIsOpenAndPreventScrolling = (open: boolean) => {
    setIsOpen(open);
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      LelandLocalStorage.setItem(
        HIDE_MOBILE_APP_PROMPT_KEY,
        addDays(Date.now(), 1).getTime(),
      );
    }
  };

  useEffect(() => {
    if (isIOS) {
      const hideMobileAppPromptExp = LelandLocalStorage.getItem<number>(
        HIDE_MOBILE_APP_PROMPT_KEY,
      );
      setIsOpenAndPreventScrolling(
        hideMobileAppPromptExp === null ||
          isDateAfter(Date.now(), hideMobileAppPromptExp),
      );
    }
  }, []);

  useEffect(() => {
    if (
      currentUser?.coach?.status &&
      !ONBOARDED_COACH_STATUSES.includes(currentUser.coach.status)
    ) {
      void router.replace('/onboarding');
    }
  }, [currentUser?.coach?.status, router]);

  return (
    <AuthedPageBase
      title="Inbox"
      childrenContainerClassName="!p-0 !lg:p-0 h-full w-full"
      titleRowClassName="p-4 mb-0"
      preventMaxWidth
      hideTitleRow
    >
      <ClientsOverview reload={router.reload} />
      <DownloadMobileAppPrompt
        isOpen={isOpen}
        setIsOpen={setIsOpenAndPreventScrolling}
      />
    </AuthedPageBase>
  );
};

export default Inbox;
